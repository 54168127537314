// Key for saving user info in the context
export const USER_CONTEXT_KEY = "appmod:webSignedInUser";

export const URLS = {
  sessionHealth: `/api/code/v1/sessions/:sessionId/health`,
};


export const AUTH_URLS = {
  me: `/api/user/me`,
};



export const ENVIRONMENT_KEYS = {
  appBase: "TECHO_APP_BASE_URL",
  codeEditorBaseUrl: "TECHO_APP_CODE_EDITOR_BASE_URL",
  authBaseUrl: "TECHO_APP_AUTH_BASE_URL",
  editorHeathUpdateInterval: "TECHO_APP_EDITOR_HEALTH_UPDATE_INTERVAL",
  editorFocusOutTimeOut: "TECHO_APP_EDITOR_FOCUS_OUT_TIMEOUT",
  extOnActiveWaitInterval: "TECHO_APP_EXT_ON_ACTIVATE_WAIT_INTERVAL",
  commands:'TECHO_APP_EXT_SIGN_IN_COMMANDS',
};

export const DEFAULT_ENVIRONMENT_VALUES:Record<string,string> = {
  'TECHO_APP_BASE_URL': "https://dev-appmod.techo.camp",
  'TECHO_APP_CODE_EDITOR_BASE_URL': "https://dev-editor.techo.camp",
  'TECHO_APP_AUTH_BASE_URL': "https://appsecauth-ai.techo.camp",
  'TECHO_APP_EDITOR_HEALTH_UPDATE_INTERVAL': '180000',
  'TECHO_APP_EDITOR_FOCUS_OUT_TIMEOUT': '300000',
  'TECHO_APP_EXT_ON_ACTIVATE_WAIT_INTERVAL': '1000',
  commands:'appmod-ai-base.signInFromWebFrontend,appmod-copilot.signInFromWebFrontend'
};
