/**
 * Generated using theia-extension-generator
 */
import { ContainerModule } from "@theia/core/shared/inversify";
import { FrontendApplicationContribution } from "@theia/core/lib/browser";

import { FrontendStartupContribution } from "./theia-startup-frontend-contribution";


export default new ContainerModule((bind) => {
  // add your contribution bindings here
  bind(FrontendStartupContribution).toSelf().inSingletonScope();
  bind(FrontendApplicationContribution).toService(FrontendStartupContribution);
});
