import { injectable, inject } from "@theia/core/shared/inversify";
import { CommandService } from "@theia/core/lib/common";
import {
  FrontendApplication,
  FrontendApplicationContribution,
} from "@theia/core/lib/browser";
import { EnvVariablesServer } from "@theia/core/lib/common/env-variables";
import { ContextKeyService } from "@theia/core/lib/browser/context-key-service";

import { getAuthSession } from "./services/auth";
import {
  AUTH_URLS,
  DEFAULT_ENVIRONMENT_VALUES,
  ENVIRONMENT_KEYS,
  URLS,
  USER_CONTEXT_KEY,
} from "./utils/constants";
import { startHealthUpdate } from "./services/health";
import { replaceParams } from "./utils/utils";



/**
 * FrontendStartupContribution is a theia front end contribution plugin
 * for more details on theia front end contribution plugin, check the docs for theia plugins.
 *
 * This plugin is used to get the AppSec user information from the code-editor frontend
 * and pass the same to code-editor extensions.
 *
 * If user information is to be used in a new extension, it will have to implement the
 * command similar to appmod-ai-base.signInFromWebFrontend
 */


@injectable()
export class FrontendStartupContribution
  implements FrontendApplicationContribution
{
  @inject(CommandService)
  protected readonly commandService: CommandService;

  @inject(ContextKeyService)
  protected readonly contextKeyService: ContextKeyService;

  @inject(EnvVariablesServer)
  protected readonly envVariablesServer: EnvVariablesServer;

  constructor() {}

  async getEnvValue(key: string) {
    const envObj = await this.envVariablesServer.getValue(key);
    return envObj ? envObj.value : DEFAULT_ENVIRONMENT_VALUES[key];
  }

  /**
   * This lifecycle hook is called when the application starts
   * This is not used other than for logging purposes.
   * @param app
   */
  async onStart(app: FrontendApplication) {
    // Not sure what this does, probably a Log
    window.postMessage(
      { type: "code-editor-start", payload: "triggered start" },
      "*"
    );
    


    
    const path = window.location.pathname;
    
    
    const sessionId = path.substring(path.lastIndexOf("/") + 1);

    console.log(`path->`, path);
    console.log(`sessionId->`, sessionId);

    const codeEditorBaseUrl = await this.getEnvValue(
      ENVIRONMENT_KEYS.codeEditorBaseUrl
    );

    const editorHeathUpdateInterval = await this.getEnvValue(
      ENVIRONMENT_KEYS.editorHeathUpdateInterval
    );

    const editorFocusOutTimeOut = await this.getEnvValue(
      ENVIRONMENT_KEYS.editorFocusOutTimeOut
    );

    if (!editorHeathUpdateInterval || !editorFocusOutTimeOut) return;

    const sessionHealthEndpoint = replaceParams(
      `${codeEditorBaseUrl}${URLS.sessionHealth}`,
      {
        sessionId,
      }
    );

    let interval: any;
    let focusOutTimeout: any;

    // Event listener for window focus event
    window.addEventListener("focus", () => {
      clearTimeout(focusOutTimeout);
      interval = startHealthUpdate(
        sessionHealthEndpoint,
        parseInt(editorHeathUpdateInterval)
      );
    });

    // Event listener for window blur event
    window.addEventListener("blur", () => {
      focusOutTimeout = setTimeout(() => {
        clearInterval(interval);
      }, parseInt(editorFocusOutTimeOut));
    });
  }

  /**
   * This lifecycle hook will be called when Layout for this theia plugin is ready.
   * @param app
   */
  async onDidInitializeLayout(app: FrontendApplication) {
    // Not sure what this does, probably a Log
    window.postMessage(
      { type: "code-editor-init-layout", payload: "triggered init layout" },
      "*"
    );

    const authBaseUrl = await this.getEnvValue(ENVIRONMENT_KEYS.authBaseUrl);

    // Making a call to AppSec to get user credentials
    // This assumes that user is logged in and cookie is available
    const session = await getAuthSession(`${authBaseUrl}${AUTH_URLS.me}`);

    // Set user to plugin context
    this.contextKeyService.setContext(USER_CONTEXT_KEY, session);

    const extOnActivateTimer = await this.getEnvValue(
      ENVIRONMENT_KEYS.extOnActiveWaitInterval
    );

    if (!extOnActivateTimer) return;

    const commandsString = await this.getEnvValue(ENVIRONMENT_KEYS.commands);
    if (!commandsString) return;

    /**
     * List of extensions or commands to which user information should be broadcasted
     */
    const commands = commandsString.split(",");

    console.log(`commands->`,commands);
    // respective commands become available when extensions are loaded
    const allExtensionsAreLoaded = () =>
      commands.every((command) =>
        (this.commandService as any).getCommand(command)
      );

    /**
     * This is a small closure that will wait for the dependant
     * extensions to be loaded and ready, once they are ready
     * it will pass the user details to the extensions.
     */
    const executeAfterAllExtensionsAreLoaded = () => {
      let timer;
      if (allExtensionsAreLoaded()) {
        for (const command of commands) {
          this.commandService.executeCommand(command, session);
        }
      } else {
        clearTimeout(timer);
        timer = setTimeout(() => {
          executeAfterAllExtensionsAreLoaded();
        }, parseInt(extOnActivateTimer));
      }
    };

    // execute the function
    executeAfterAllExtensionsAreLoaded();
  }
}
